import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import dataCountry from "../../public/data-country";
import { Typography, InputBase, Divider } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const PhoneCodePicker = (props) => {
  const classes = useStyles(props);
  const { handleSelectPhoneCode, t, onClose } = props;
  const [search, setSearch] = useState("");
  const [filteredSearch, setFilteredSearch] = useState(dataCountry);

  useEffect(() => {
    setFilteredSearch(
      dataCountry.filter((val) =>
        val.name
          .toLocaleLowerCase()
          .match(new RegExp(search.toLocaleLowerCase()))
      )
    );
  }, [search]);

  return (
    <div className={classes.phoneCodePickerWrapper}>
      <div className={classes.searchWrapper}>
        <div className={classes.closeDrawer} onClick={onClose}></div>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={t("look-for-the-name-or-country-code")}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            fullWidth
            value={search}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search.length !== 0 && (
            <div className={classes.closeIcon}>
              <CloseIcon onClick={() => setSearch("")} style={{ height: 16 }} />
            </div>
          )}
        </div>
      </div>
      <Divider style={{ paddingTop: 87, backgroundColor: "white" }} />

      <div style={{ backgroundColor: "#ffffff", marginTop: 16 }}>
        {filteredSearch.map((res, index) => (
          <React.Fragment key={index}>
            <div
              className={classes.between}
              style={{ padding: 16, cursor: "pointer" }}
              onClick={() => handleSelectPhoneCode(res)}
            >
              <div className={classes.alignItemsCenter}>
                <img
                  src={`/img/flags/${res.country_code.toLocaleLowerCase()}.png`}
                  height={20}
                  width={30}
                  alt=""
                />
                <Typography style={{ fontWeight: 600, paddingLeft: 20 }}>
                  {res.name}
                </Typography>
              </div>
              <Typography>{res.dial_code}</Typography>
            </div>
            <Divider />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PhoneCodePicker;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneCodePickerWrapper: {
      width: "100%",
      maxWidth: 444,
      margin: "auto",
      height: "100vh",
    },
    search: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: `#f1f5f9`,
      marginLeft: 0,
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },
    searchWrapper: {
      padding: "20px 16px",
      backgroundColor: "#ffffff",
      width: "100%",
      maxWidth: 444,
      position: "fixed",
      zIndex: 1,
      borderRadius: "20px 20px 0 0",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#a0a4a8",
    },
    closeIcon: {
      color: "#a0a4a8",
      marginRight: 10,
      cursor: "pointer",
      display: "flex",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      padding: theme.spacing(1.8, 1.8, 1.8, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px) !important`,
      transition: theme.transitions.create("width"),
      width: "100% !important",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
      fontSize: 14,
    },
    between: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    alignItemsCenter: {
      display: "flex",
      alignItems: "center",
    },
    closeDrawer: {
      height: 5,
      width: 100,
      backgroundColor: "#e4e4e4",
      borderRadius: 30,
      margin: "20px auto",
      marginTop: 0,
      cursor: "pointer",
    },
  })
);
